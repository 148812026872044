<template>
  <v-container>
    <v-row>
      <v-col
        class="mb-5"
        cols="12"
      >
        <v-responsive :aspect-ratio="16/9">
          <video-player  class="vjs-custom-skin"
            :options="playerOptions"
          >
          </video-player>
        </v-responsive>
        <v-card
          class="mx-auto"
        >

          <v-list>
            <template v-for="(comment, index) in comments">

              <v-divider
                :key="index"
                v-if="index > 0"
              ></v-divider>

              <v-list-item
                :key="index"
              >
                <v-list-item-avatar>
                  <v-img :src="comment.channel_avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="comment.channel_name"></v-list-item-title>
                  <v-list-item-subtitle v-html="comment.comment"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-col
                v-bind:key="index"
              >
                <v-list v-if="comment.replies.length" class="replies">
                  <template v-for="(reply, index) in comment.replies">
                    <v-divider
                      :key="index"
                    ></v-divider>

                    <v-list-item
                      :key="index"
                    >
                      <v-list-item-avatar>
                        <v-img :src="reply.channel_avatar"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title v-html="reply.channel_name"></v-list-item-title>
                        <v-list-item-subtitle v-html="reply.comment"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import 'video.js/dist/video-js.css'
  import { videoPlayer } from 'vue-video-player'

  export default {
    name: 'HelloWorld',
    components: {
      videoPlayer
    },
    data: () => ({
      playerOptions: {
        // videojs options
        autoplay: 'auto',
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        aspectRatio: '16:9',
        sources: [{
          type: "video/mp4",
          src: `${process.env.BASE_URL}TRIBUTE_TO_THE_KING_OF_IRL_notMARLEY_RIP--0pG_mnrK-g.mp4`
        }],
        poster: `${process.env.BASE_URL}thumbnail.jpg`,
      },
      comments: [
        {
            "channel_name":"Destiny /Dest",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniz0Pwi1mAFXFPKjzmGKvm6Y3ZWrZLm6xnzYcoz=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"MX in the chat❤️ thank you all for the supporting, he appreciated and adored each and everyone of you ❤️MX forever",
            "likes":85,
            "replies":[
              {
                  "channel_name":"Ronald",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnic2h5dVFXhP-YJGqZ2fpQUTBO54dOeQRQFZadvNQ=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Condolences to you, his family and friends. Mx",
                  "likes":5
              },
              {
                  "channel_name":"O.G Frank White",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnj4PY6Rx55dYa30D1nAwXJDEsor6143aqAe929DnQ=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"MX 💗",
                  "likes":1
              },
              {
                  "channel_name":"THEDANISHVIKING",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhjHNI3mWZyz7Uv_3IwAR5jSpeowHy4lg2_a9pyTQ=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"My condolences dest to you and your mom❤ r.i.p marls 😭 stay strong!",
                  "likes":1
              },
              {
                  "channel_name":"ladypam#4292",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhFXr4AfA82xpLRWoZXvpqAI050-kIFkYL29ikoaQ=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"omg im,e so sorry i just heard what took him.i am in tears. love you marley xxx",
                  "likes":2
              },
              {
                  "channel_name":"Destiny /Dest",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniz0Pwi1mAFXFPKjzmGKvm6Y3ZWrZLm6xnzYcoz=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Thank you all❤️🙏🏽",
                  "likes":2
              },
              {
                  "channel_name":"ladypam#4292",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhFXr4AfA82xpLRWoZXvpqAI050-kIFkYL29ikoaQ=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"@Destiny /Dest stay strong we are all here for you and your family love you heaps xxx",
                  "likes":1
              },
              {
                  "channel_name":"J T",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjtevFndUEuCqRJID0C-NNFDoPTfFy8pA93INEa=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"The first stream that I found of his, I felt out of place. I live across the ocean. I didn't know the slang, I didn't know him or his friends, but he made me laugh. He was sincere and genuine to not only me to make me feel more than welcome and comfortable, but he was sincere to everyone that came to watch and support him. Your brother had a great sense of humor and a even more amazing heart. I'm so thankful for every time he's made me laugh or smile. I'm so so terribly sorry for your loss. You, your family, and every heart he's ever touched will be in my prayers.😞♥️",
                  "likes":0
              },
              {
                  "channel_name":"Kaesha Jayne",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniGc_NTQK8tE0y8SmIPV-1poedOOOf5T3bBPqGD=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Condolences 🙏🏽",
                  "likes":0
              },
              {
                  "channel_name":"Nixie ~ ♡ ~",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjuX4YInzOv7bI69v2bBIaDaiL8WaPTwZQK1H5ITA=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Thinking of you all 🙏❤MX forever 😢 xx",
                  "likes":0
              },
              {
                  "channel_name":"eeevansuk1",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniugKB2JpLEbXIXqueqzapMBbNkShxR4iT5RVT9=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Mx",
                  "likes":0
              },
              {
                  "channel_name":"d g",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngAN-nNdn9Iu8TVGfITq_wfPSWI6Xjgk3NjjQ=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Still cant believe it..  R.I.P Marley. Mx",
                  "likes":0
              },
              {
                  "channel_name":"Colonel Sanders",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniXGqy3pDX34d-_VWUBlVSw_dTccSFhlsXXNZU8FA=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"SORRY 4 UR LOSS -- MARLEY WAS A G :(",
                  "likes":4
              }
            ]
        },
        {
            "channel_name":"Rains Mx",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniRy2PlHOYbaaB6kZMZ8ouKvOekPValbsC6CA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"He set the bar for IRL. No one has come close. Can't believe he's gone 😭😭😭Mx ☝️",
            "likes":16,
            "replies":[
              
            ]
        },
        {
            "channel_name":"kerri B",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng84sPM1Ndi5As0LEhRdCaQceIBsB3rmQ0808Mb=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"R.I.P Marley ❤️ so sad 😢 thoughts and love to his family ❤️ MX",
            "likes":16,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Sophie Mx",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngUJZ8FDm1vUDZtz6baMfMRTbma2FSJmO6l8Spa=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Mx ❤️☝️ RIP Marlz ❤️❤️❤️❤️❤️❤️",
            "likes":29,
            "replies":[
              
            ]
        },
        {
            "channel_name":"THEDANISHVIKING",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhjHNI3mWZyz7Uv_3IwAR5jSpeowHy4lg2_a9pyTQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Omfg rest in peace Marley! God damn i am crying 😭",
            "likes":13,
            "replies":[
              
            ]
        },
        {
            "channel_name":"A Friend",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniWUDY6t-7Mcq4ECZ_0PxVhF7KrR08NeW-_Rknm=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"He’s trolling right? How?! Wow my god. Condolences to mom, dest and his beautiful children. I’m truly shocked, and so sad to hear that... Rest in paradise my Ghee☝️ No iRL streamer made me laugh like you did. Good job Tester. Mx",
            "likes":15,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Scuffed Beatz",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjRRQV7h_cS-DIMlGZlmTZO93KokxZde2M41Htk=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Put tracksuit to shame was the UK content king R.I.P",
            "likes":20,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Liutauras",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhT8WbsafgSJ2pe3eBx1Gx6DXmMwFLcbMrnWO35kA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP",
            "likes":36,
            "replies":[
              
            ]
        },
        {
            "channel_name":"ghost",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniyoINOv_GZsSNKtEIUhetB6L6B6jyUoLMDOFNP_w=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"I don't know why but this hurts a lot. RIP notMARLEY",
            "likes":21,
            "replies":[
              
            ]
        },
        {
            "channel_name":"J T",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjtevFndUEuCqRJID0C-NNFDoPTfFy8pA93INEa=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Every single stream, he would make everyone watching smile and laugh. He would make everyone's day even just a little bit better. The dedication to his streams and audience was unmatched. Marley, thank you for all the laughs. You've made so many people happy all over the world. Rest easy brother.♥️🙏",
            "likes":17,
            "replies":[
              
            ]
        },
        {
            "channel_name":"FernandoFireFCB",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhP4beCwgfaH4_1lH5gpBsct-IwrjcOd9uDJkX_=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Damn... I can't believe someone died that's part of our community... RIP NotMarley",
            "likes":38,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Deal city",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwni4DKZkKyCbiRJc0kfPitmpAUqyBmr8m6D7kbgv=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Rip to the best UK streamer",
            "likes":15,
            "replies":[
              
            ]
        },
        {
            "channel_name":"sf sf",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjv5lwKeDv4HQKZrZJQjuhlfF8Vxov-TCR8fUrQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"would often tune in after work. can't think of any other british streamer that i've watched who provided better content. i enjoyed his meet up streams, the ones where he met up with his viewers and his mother & sister, that was a really good stream. he constantly put himself in situations where the content was, i mean who else would have the balls to go to a stranger's home for 'book club' and then troll the shit out of them haha. he did not have a huge following, but he had one, and he will be remembered. thoughts with his friends & family",
            "likes":18,
            "replies":[
              
            ]
        },
        {
            "channel_name":"pj48076",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjYGUn5AAID82OvUeK1lL1I_-CoOEvOVeXNMg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"He touched our hearts and will never be forgotten.",
            "likes":9,
            "replies":[
              
            ]
        },
        {
            "channel_name":"菩薩Bodhicitta Mx",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjIv4ftRDurt5tubjj79kNYWHM27TWg_30AesKtoQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"We'll never forget you Marley! Until we meet again, bro! Much love and my heart goes out to ya family!",
            "likes":23,
            "replies":[
              {
                  "channel_name":"ladypam#4292",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhFXr4AfA82xpLRWoZXvpqAI050-kIFkYL29ikoaQ=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"justin stay strong much love xx",
                  "likes":1
              }
            ]
        },
        {
            "channel_name":"Tatsuhiro Satou",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnibJnuVDHf_BCX7TxZKmuS7S0l1qyVw23oQAg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"damn this is sad, i remember the uk road trip, tsa got mad because he cucked him, rest in peace marley",
            "likes":27,
            "replies":[
              
            ]
        },
        {
            "channel_name":"ً",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjXna1yMz3Ii3vHiBVazUt_DIu_aIKuNh9e2XPRaw=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"R.I.P notMARLEY my favourite UK streamer was here from the start and witnessed the journey till the end, been a ride mai gee Mx",
            "likes":23,
            "replies":[
              {
                  "channel_name":"oxygen thief",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnitDxrCKbQXL8ABZBx5kJDYPrjUIQDAMzD6cw=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Same here,such a shame he was a real one..condolences his family",
                  "likes":3
              }
            ]
        },
        {
            "channel_name":"suzy fingermecrotch",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhxvMdJqn_0U3Dwr0jwlRUz-ygP8i9om6vP5Q=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Much love brother 🕯🕯🕯",
            "likes":12,
            "replies":[
              
            ]
        },
        {
            "channel_name":"La Pulga Atomica",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhjqdCSZx6ZbOQGkiz9qIiCGKHoIXgfRcmzZhdE9A=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP Marley🙏 The most genuine streamer",
            "likes":22,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Naahdude",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhdEGcOe7MEgSYTPqzM9fkrGj_qrPsWYovRSqFQ3A=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP! He was indeed a very humble and funny guy! A people's person for sure! He's in a more humble place now!",
            "likes":7,
            "replies":[
              
            ]
        },
        {
            "channel_name":"FREDDIE BEANS",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng4pMPtn4Do5zcZQ8VkXdpU8DprGTSK-cRiiF-sFQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP marley truly is so sad.",
            "likes":21,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Stop Speeding",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngCFNWTSBVN-WBgzetljVH0Rp4w0r72fHLtxe1V=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Dang wish I knew about you when you was alive.  You have a great energy.",
            "likes":17,
            "replies":[
              {
                  "channel_name":"BestDongerKR",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhVnndtmjDttRbkZq3Skcii_fGQ6IVIveBKRBn6YQ=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Respect for showing ur condolences ss",
                  "likes":3
              }
            ]
        },
        {
            "channel_name":"4skin",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjBmXopwjiRL74EwxJHeURA5lFqQ7yd0PwD7ozkjA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"rip man.. provided a lot of content during the ice & tsa times for me.",
            "likes":8,
            "replies":[
              
            ]
        },
        {
            "channel_name":"R. S.",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhoLSkI44JqwBAPWwNS_KfYRR9f5_s_LBpXdHyFtg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"R I P Marley and god bless to your Family and Friends 🙏❣️💐⭐",
            "likes":11,
            "replies":[
              
            ]
        },
        {
            "channel_name":"vlWhite Lightlv",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng85tDfb7-UzRrp2Ehk2FXi35YvGlFJ4RkZw_SFUg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"I’m going to miss this guy damnn",
            "likes":13,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Hue Jassle",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjTZSt5G27AyHC6k73oU5Rv-EMtrT0v_7TFKMhKcA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"MX IN THE CHAT BOYS...",
            "likes":12,
            "replies":[
              
            ]
        },
        {
            "channel_name":"JPod",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhay8rpPuA77aJzIE5fMqJoiPtdHtJcDxLRsXdR=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Devastated, he will be greatly missed, Mx in the chat",
            "likes":11,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Lisa Fan",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhImr0VD8Kmne5PF6s6R-twntL41UYBzjhdqA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"that's sad bro he was actually a pretty funny dude just never really blew up",
            "likes":26,
            "replies":[
              
            ]
        },
        {
            "channel_name":"pivix",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng1ps_xbXYopOZlVUc4TH1NGRI1lmjAwk5_KGDjzA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP :(",
            "likes":11,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Crumpet",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngX-8HsWZ_9olnzazByH9BCHh_iy9hfQ6rPOKFiUQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP. You will be remembered in the live streaming community, Marley!",
            "likes":14,
            "replies":[
              
            ]
        },
        {
            "channel_name":"O.G Frank White",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnj4PY6Rx55dYa30D1nAwXJDEsor6143aqAe929DnQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Can't believe it man 😢 💔\nR.I.P my g",
            "likes":10,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Strong Jewel",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhONQuvAgtWsD0PzSp2vOuUwPQkLREEjUWGOGVm=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Omg I’m lost for words seriously Rest In Power your energy lives on champion 💙I’m sending all my love strength comfort support out to his family 😔💔🙏🏽❤️",
            "likes":8,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Daniel",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhPDk0BvF1GwlEDn6r63XUfUwOwdbpxBLPA3mf2lA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP King",
            "likes":16,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Colonel Sanders",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniXGqy3pDX34d-_VWUBlVSw_dTccSFhlsXXNZU8FA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP BRO",
            "likes":13,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Karma IsReal",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwni7jDf2D1XTSFnCtGytigyRrLKthuCWSYHCmyYrWJM=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP 😢🙏",
            "likes":13,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Vince The Man McMahon",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjdvTzerIIL21ggfQpBgZL-E8aKtRC0OzWxz2uu_g=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"What happened?? RIP BROTHER!",
            "likes":10,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Aquaberry Aquarius",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhi2a0irbiv8wph5ICmK4pQOH7SYQTawpCrygbP=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Damn.. why couldn’t they have taken Lemon Dropz instead...",
            "likes":14,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Seb Williams",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngsImxwd9jjRohuE8kPWivcMyFrYvDRp2ddgI84ew=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Absolutely gutted.... Can't believe it. Thoughts and prayers to his mum, Destiny, his kids, and friends... MX ☝🏻 ♥️😢😢🙏🏻🙏🏻",
            "likes":7,
            "replies":[
              
            ]
        },
        {
            "channel_name":"2gDRAWS",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniUXJbCfdCPE54ZRRrIHgiP9VPTaadGrT8ORggF=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Damnnn RIP Marley man 😔🖤",
            "likes":8,
            "replies":[
              
            ]
        },
        {
            "channel_name":"justice prevails",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng-Mf_WMOqXRd08VJWyvc34UuAb7HN-6Cvykusj0Q=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Great tribute 👑keep them coming ❤",
            "likes":7,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Angel Eyes",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngu42QNkrAHSdMxyJkIWw5ngSxYdcovq3hx_8GXag=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Hard to believe , You will be remembered RIP my friend.",
            "likes":13,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Dscuff Man",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnijAhC1TRT740tVHB9fxI3DTc6ixXJOfjaOD3wE=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"How did he die",
            "likes":7,
            "replies":[
              
            ]
        },
        {
            "channel_name":"T U R K - 1 8 2",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwni0Zl6ZR-XVBl7iftCKM7xJxVadFL754N23bkrdLQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Rip💜",
            "likes":10,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Hannah Marie",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjUh9g0WqgJ4Hzkg9RzrouzH08INtHUUkMXWt7WKw=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"What happened to him??? RIP man thats so sad",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Gascans",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniirUlVbe2pLAnDFxPuRWb42z3wwXrXuHrals1r-A=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Rip man   hope your in a better place.  Xx",
            "likes":8,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Daniel Dillon",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjWMAjG67kCb9oOTgqtwZtF7W7sr93oJg0NGCIyBg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Man this is crazy feelsbadman",
            "likes":7,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Lyonsytron",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnj-PnQtC-SQgcQANgALWI0ueKYcrVmmY2XSk1tl=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Jah bless, he was our bumbaclart xx",
            "likes":9,
            "replies":[
              
            ]
        },
        {
            "channel_name":"POVERTY SAUCE",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnh4Ftvnc6Bu0ga7CLl3BdQA2kgvpI_QHghVz7mkvA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"He in the biggest mansion now, RIP",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Kali",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnieylTN08OZYkcUmGlMpxyeFQY_auKrqhb0rKR0=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"I love you Marley. My brother. MX in the chat! LASHH OF THE LIKE , SUPPORT THE STREAM!",
            "likes":12,
            "replies":[
              {
                  "channel_name":"justice prevails",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng-Mf_WMOqXRd08VJWyvc34UuAb7HN-6Cvykusj0Q=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"❤❤",
                  "likes":0
              },
              {
                  "channel_name":"Seb Williams",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngsImxwd9jjRohuE8kPWivcMyFrYvDRp2ddgI84ew=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Can't believe it bro, take care mate 👊🏻",
                  "likes":0
              }
            ]
        },
        {
            "channel_name":"Stoopid Genius",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngtMdGNghfZzobkNZ6FJSD-3yV739babXR1CGpTQg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"CyberPal Yellow Almighty informed me of this angels parting. Cheers to remembering the good and eliminating the bad through NotMarleys smile, laughter and more! 🤙💙🤙💙🤙💙🤙💙",
            "likes":8,
            "replies":[
              
            ]
        },
        {
            "channel_name":"RandomnessTube",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjxbyHvX1NeZ6DmZ0Y9CEISiINv4ddq971ncSe0wg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"One of the very few streamers that actually liked his chat rest in peace lad.",
            "likes":7,
            "replies":[
              
            ]
        },
        {
            "channel_name":"IZzwizZY",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjMCrneIju9mEOTc0ItZx-zKL_yjDo7K2zUkD-OFA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Rest in peace my friend. I won't forget the good times.",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Piggirl Play",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniuyf9kwLwiNbi6Yd1fDNw5QpO8igAWKqOlPw=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Rip Not Marley you were a genuine nice guy. hilarious Legend RIP",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"jamie",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniaivuGpHaFWqcIyCBA_-0RFKZZOkFLxMiIYy_1_g=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"I can't believe it rip dude",
            "likes":7,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Not James",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnj2qIQAtVvuMkKSd_ZwbmS7_N0yaOQMYg5F9ZVK=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP homie",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Aios TV",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjNbWQkmV1x4jJKtSF_scd4mREW-K7mSx9NXjvSIA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP Marley🙏",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Scuffed Paul Denino",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwni46LtkVvz4VKj9eKKeVnX1Y5wko8SNz2-Q_Py4=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Never heard of this dude",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Lyonsytron",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnj-PnQtC-SQgcQANgALWI0ueKYcrVmmY2XSk1tl=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"He was a good bloke",
            "likes":13,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Dexter Morgan",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniDEOgwC0KHqpfJ5QFv8OQQEEj-SjDdjcpL=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Still can't believe it such a joyous guy made everyone laugh even when he would join in the chat and wind up Justin Justin would end up laughing too he will be missed  gone too soon but will never be forgotten O.G of the live stream game and O.G youtuber of Luton",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Sammyyy -",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngwc4xMKijtWtjw4V2nL8fVozRBiuMdLXhX_CEH=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Mx 💖 RIP Marley",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"MrCraiggyT",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjIoFbGgssmN4u2zAmNciXE8LNCirKU8pAWKAi1=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"wow... RIP Marley you will be missed my friend 🙏 #MX",
            "likes":10,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Rooftop Hoppin",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnio_qNj7YU67i8ESWB-eThXkEnlwyk9RxQNw8Fa=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"R.I.P brother. Mx ☝🏽️",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"holla atme",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjWeSL1n2AMj5MCJtvfdCeFlYyikTT15Vp3wq72=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Rip dude, thanks for the laughs.",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Druid",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniTvbFNkYjiXnL042gBLN33rtfDSbsNUpKr0ncu=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"MX RIP :(",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"BRITISH B",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniOwEO1JYkUE_A4xG-y21AhJXLeddJUJz9E4CM4nw=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Good video good man",
            "likes":10,
            "replies":[
              
            ]
        },
        {
            "channel_name":"ALCOSSS",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhoq_FDXzx6iEL4vE8MdeSr_KTFSGDHg6FFNw=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"wow so sad to hear he passed. dude was proper genuine and always about a laugh. rip",
            "likes":1,
            "replies":[
              
            ]
        },
        {
            "channel_name":"DarkWorldOrder",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhr4wd1hu83-VVxq2uCBEjUlvq_K2t30DowjuRF7g=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Mx",
            "likes":5,
            "replies":[
              
            ]
        },
        {
            "channel_name":"P O",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniMYuej5RCKg405q7BszDqRne2Lw-RF1GGgXCBw=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP 💜",
            "likes":3,
            "replies":[
              
            ]
        },
        {
            "channel_name":"KZ Auckland",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnj9U3HZNF1_x3-WUZ4agtD1e_GZcRgy94Go0Eb5cg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Nice work \r@TESTER he would of loved it. RIP my bro. See you when I get there",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Fascination land",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhZQcDTJ5HQjjLYbOJF3-qYxPi7u6h9AKk7mJPRdA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"I'll do him justice",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Nixie ~ ♡ ~",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjuX4YInzOv7bI69v2bBIaDaiL8WaPTwZQK1H5ITA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"❤Still though :( come back Marley. 💕Pray your heart isn't in as much pain now ❤",
            "likes":0,
            "replies":[
              
            ]
        },
        {
            "channel_name":"DayNNightOG",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnh7KdPJzuSY2ZdMJ0EcCM0pUHycjiii8Q7g_O1sGg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP",
            "likes":4,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Robbie 10",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniDoW6doipNdoas7od7f_qvCNWUdt_Il0oxGKez=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"RIP",
            "likes":4,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Born Blessed",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniGIpUEAgpQWln17IxtjidxqlbRqxde8yJ3kp6m=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"How did he die?",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"blue sky",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngv0-jIpMhGgJGHwHO49Pa6GV7XFCSt5IUOWg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Absolute legend always the good guys 🙏😇",
            "likes":1,
            "replies":[
              
            ]
        },
        {
            "channel_name":"The Myth Right Here",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnirzIyzFF7SchAgefUD88-diQjUmdBa6bjdibfx=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Rest In Peace Man",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"ReLeVaNcE 2047",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngJCwk8CyFLY0ce896Bl2-X3ETqvTcH76Rt3FxTug=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"How’d he die?",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Empress Stush",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnh5yYCDYO8noYEl41QEdi-3QeYGF4JLC-_se2V7=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"🙏🏽 Mx🙏🏽",
            "likes":3,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Harambe",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjPZYFWsuAEnHwrJo1T_ERVmGDbpuwXaELBfhx62w=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"rip",
            "likes":4,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Ronald",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnic2h5dVFXhP-YJGqZ2fpQUTBO54dOeQRQFZadvNQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"I cant even believe it ... this is so sad. He was always so positive and seemingly mentally stable and happy person. For the past year he only streamed for like once a month and I would always watch his streams whenever i could. He was a great troll and one of the most underrated IRL streamers, he never found the audience he deserved. RIP man..... you will be truly missed.",
            "likes":16,
            "replies":[
              {
                  "channel_name":"A Friend",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniWUDY6t-7Mcq4ECZ_0PxVhF7KrR08NeW-_Rknm=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Well said Ronald",
                  "likes":4
              },
              {
                  "channel_name":"Nixie ~ ♡ ~",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjuX4YInzOv7bI69v2bBIaDaiL8WaPTwZQK1H5ITA=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"❤",
                  "likes":2
              }
            ]
        },
        {
            "channel_name":"Nixie ~ ♡ ~",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjuX4YInzOv7bI69v2bBIaDaiL8WaPTwZQK1H5ITA=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"❤Rest easy warrior,🙏 you'll be deeply missed by all the lives you touched 💚 MX till the end ☝ xx",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Rdannylor2",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhCNjuNiO2G10JWOXGck9IZMpXGU0S9mgo0UiQ9=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Man so sad. Been missing his streams for a while last I heard he just had a hair transplant and would return to streaming. He brightened my days with his live streams and will be greatly missed. UK IRL King",
            "likes":22,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Mr Misunderstood",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnj06fEf914O0nxnu4H8bLflALxwAupM_Z9KbQFJNQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"R.I.P. Marley, I'm so sorry you had to leave us like this, you were definitely one of the most underated streamers trying so hard to give entertainment when you could no matter what the struggle, it makes me even more sad that I have your number on my phone and you're not on the other side to answer my texts, so sorry bro,  you will be missed, rest in peace brother <3",
            "likes":8,
            "replies":[
              
            ]
        },
        {
            "channel_name":"2H2K",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwninwpIB_Vr5W5ddYO96Fn7QG3r1Y2LY7i9KvZ9vPQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Wtf?",
            "likes":5,
            "replies":[
              {
                  "channel_name":"XANAX GOD805",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwngD7ZF3c5liZqHA2SYTlDUyfKniMRLYxTLPGAHO=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Indeed",
                  "likes":1
              }
            ]
        },
        {
            "channel_name":"Mikie 00",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng2g9HE6TK1odJb_J6Es59HzVr2jwIW6MNN48d6=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"How do you know he’s dead?",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Vince The Man McMahon",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnjdvTzerIIL21ggfQpBgZL-E8aKtRC0OzWxz2uu_g=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Mx 😇",
            "likes":2,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Harry c",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnioX_GJ_9Hdv6jbN3inPrIGqpV-1cp7IbC6ZaO8=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"what happened to him?",
            "likes":15,
            "replies":[
              {
                  "channel_name":"Mikie 00",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng2g9HE6TK1odJb_J6Es59HzVr2jwIW6MNN48d6=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Harry c same I’m confused",
                  "likes":0
              }
            ]
        },
        {
            "channel_name":"Alias Jones",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniq5AMhrHMvcAbI7xiz3ctlyJi7ZXPnYc7owB8D=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"I spent some time with Marley when he was in Los Angeles. How did he die???",
            "likes":6,
            "replies":[
              
            ]
        },
        {
            "channel_name":"eeevansuk1",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwniugKB2JpLEbXIXqueqzapMBbNkShxR4iT5RVT9=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"OMG...words can't express how devastated I am ...loved this guy's streams...R.I.P brother",
            "likes":19,
            "replies":[
              {
                  "channel_name":"oxygen thief",
                  "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnitDxrCKbQXL8ABZBx5kJDYPrjUIQDAMzD6cw=s48-c-k-c0xffffffff-no-rj-mo",
                  "comment":"Yea such a shame he was def the best UK IRL streamer,he was such a funny genuine guy..he will be missed. Mx",
                  "likes":6
              }
            ]
        },
        {
            "channel_name":"Mikie 00",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng2g9HE6TK1odJb_J6Es59HzVr2jwIW6MNN48d6=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Is he actually dead?",
            "likes":1,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Sharaloui",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwng5uxX5psm7Q9NLmEkaGvkO5MQCZzhuHWEikbR6Jw=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"This is such Sad sad news.  He was so funny, so talented and so genuine.  Rest In Peace Not Marley you will be missed.",
            "likes":9,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Chris Pulsar",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnj_dzGmDa9XSV116gFVkFFIDV1Iz2edGA-6TCR5nQ=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Marley was one of the good ones respect roadman.",
            "likes":12,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Clout Gangster",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhqu5h7sdkTuX_uxs_avWikFb4sJujiNbjgxJIg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"Marley Always Wins💯☑️",
            "likes":18,
            "replies":[
              
            ]
        },
        {
            "channel_name":"Clout Gangster",
            "channel_avatar":"https://yt3.ggpht.com/ytc/AAUvwnhqu5h7sdkTuX_uxs_avWikFb4sJujiNbjgxJIg=s48-c-k-c0xffffffff-no-rj-mo",
            "comment":"😞",
            "likes":12,
            "replies":[
              
            ]
        }
      ],
    }),
  }
</script>

<style>
.vjs-custom-skin > .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -1em
}
.vjs-custom-skin > .video-js {
  width: 100%;
}

.v-list-item__subtitle {
  overflow: visible !important;
  text-overflow: unset !important;
  white-space: normal !important;
}

.v-list-item__avatar {
  align-self: start;
}

.replies {
  margin-left: 60px;
}

</style>